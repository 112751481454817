import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./BulletsTwo.scss";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "../../index";
import { Heading, Button } from "@chakra-ui/react";
import { List } from "../../ui/List";

export const BulletsTwo = (props) => {
  const { title, bullets } = props;
  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const titleSize = isMobile ? "lg" : "2xl";

  return (
    <section className="bullets-two-wrapper top-space">
      <Heading className="bottom-space" size={titleSize}>
        {title}
      </Heading>
      <div className="bullet-grid" style={{ width: isMobile ? "90%" : "65%" }}>
        <List items={bullets} />
      </div>

      <Button
        className="bottom-space"
        colorScheme={colorScheme}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </section>
  );
};

BulletsTwo.propTypes = {
  title: PropTypes.string.isRequired,
  bullets: PropTypes.array.isRequired,
};
