import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Bullets.scss";
import { ThemeProvider } from "../../index";
import { Button, Heading } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { List } from "../../ui/List";

export const Bullets = (props) => {
  const { title, bullets, subtext } = props;
  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const titleSize = isMobile ? "lg" : "2xl";
  const subtextSize = isMobile ? "sm" : "lg";

  return (
    <section className="bullets-wrapper">
      <Heading className="bottom-space" size={titleSize}>
        {title}
      </Heading>
      <Heading className="bottom-space" color="grey" size={subtextSize}>
        {subtext}
      </Heading>
      <div
        className="bullet-grid bottom-space"
        style={{ width: isMobile ? "90%" : "65%" }}
      >
        <List items={bullets} />
      </div>

      <Button
        className="bottom-space"
        colorScheme={colorScheme}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </section>
  );
};

Bullets.propTypes = {
  title: PropTypes.string.isRequired,
  bullets: PropTypes.array.isRequired,
  subtext: PropTypes.string.isRequired,
};
