import React from "react";
import PropTypes from "prop-types";
import "./Icons.scss";
import { isMobile } from "react-device-detect";
// import { ThemeProvider } from "../../App";
import { Heading, Text } from "@chakra-ui/react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export const Icons = (props) => {
  const { title, iconText } = props;

  const mapped = iconText.map((iconInfo, i) => {
    return (
      <div key={i} className="icon-text">
        <Icon icon={iconInfo.faIcon} size={isMobile ? "2x" : "5x"} />
        <Heading className={isMobile ? "" : "top-space"} size="md">
          {iconInfo.title}
        </Heading>
        <Text className="bottom-space" style={{ marginTop: 12 }} size="md">
          {iconInfo.subtext}
        </Text>
      </div>
    );
  });
  return (
    <section className="icons-wrapper top-space">
      <Heading>{title}</Heading>
      <div className="icon-three-wrapper top-space">{mapped}</div>
    </section>
  );
};

Icons.propTypes = {
  title: PropTypes.string.isRequired,
  iconText: PropTypes.array.isRequired,
};
