import React, { useContext } from "react";
import "./Header.scss";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ThemeProvider } from "../../index";
import logo from "../../assets/imgs/lete.png";
import { HamburgerIcon } from "@chakra-ui/icons";
import { isMobile } from "react-device-detect";
import { useResizeDetector } from "react-resize-detector";
import { Link, withRouter } from "react-router-dom";

const Header = (props) => {
  const { width, ref } = useResizeDetector();
  const global = useContext(ThemeProvider);
  const {
    primaryButton: { buttonText, onClick },
    colorScheme,
  } = global;
  const { menuItems } = props;

  const handleClick = (title, link) => {
    if (link.includes("/")) {
      window.location.href = link;
    } else {
      props.history.push(title.split(" ").join("-"));
    }
  };
  const mapped = menuItems.map((item) => {
    return (
      <Text
        className="nav-item"
        p="12px"
        key={item.title}
        fontSize="md"
        onClick={() => handleClick(item.title, item.link)}
      >
        {item.title}
      </Text>
    );
  });
  mapped.push(
    <Button
      style={{ marginLeft: 48 }}
      size="md"
      minW={250}
      colorScheme={colorScheme}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );

  const menuArray = [...menuItems, buttonText];
  const menu = (
    <Menu>
      <MenuButton as={IconButton} icon={<HamburgerIcon />} />
      <MenuList>
        {menuArray.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => handleClick(item.title, item.link)}
          >
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  return (
    <div>
      <div style={{ height: isMobile ? 80 : 150 }} />
      <header ref={ref} className="header-wrapper">
        <Link to="/">
          <img className="header-logo" src={logo} alt="logo" />
        </Link>
        {isMobile || width < 1200 ? (
          menu
        ) : (
          <span className="menu-span">{mapped}</span>
        )}
      </header>
    </div>
  );
};

export default withRouter(Header);
Header.propTypes = {
  menuItems: PropTypes.array.isRequired,
  history: PropTypes.object,
};
