import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./BulletsThree.scss";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "../../index";
import { Heading, Button } from "@chakra-ui/react";
import { List } from "../../ui/List";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export const BulletsThree = (props) => {
  const { title, bullets } = props;
  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const titleSize = isMobile ? "lg" : "2xl";

  return (
    <section className="bullets-three-wrapper">
      <Heading className="top-space bottom-space" size={titleSize}>
        <Icon style={{ marginRight: 12 }} icon="globe-americas" size="1x" />
        {title}
      </Heading>
      <div className="bullet-grid" style={{ width: isMobile ? "90%" : "65%" }}>
        <List items={bullets} />
      </div>
      <Button
        className="bottom-space"
        colorScheme={colorScheme}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </section>
  );
};

BulletsThree.propTypes = {
  title: PropTypes.string.isRequired,
  bullets: PropTypes.array.isRequired,
};
