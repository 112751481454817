import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./InformationTwo.scss";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "../../index";
import { Button, Heading, Text } from "@chakra-ui/react";
import icon from "../../assets/imgs/custom-icon.png";
import img from "../../assets/imgs/info-two-image.png";

export const InformationTwo = (props) => {
  const { title, subtextOne, subtextTwo, bullets } = props;
  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const size = isMobile ? "lg" : "md";

  const mapped = bullets.map((item) => {
    return (
      <span className="bottom-space-small" key={item}>
        <Text className="bottom-space-small" fontSize={isMobile ? "xs" : "md"}>
          <img src={icon} style={{ height: 24, display: "inline-block" }} />{" "}
          &nbsp;&nbsp;&nbsp;{item}
        </Text>
      </span>
    );
  });
  return (
    <section className="top-space bottom-space information-two-wrapper">
      <div className="left-wrapper">
        <img
          width={"70%"}
          src={img}
          alt="computer"
          className="bottom-space top-space"
        />
        <Button colorScheme={colorScheme} onClick={onClick}>
          {buttonText}
        </Button>
      </div>
      <div className="right-wrapper top-space">
        <Heading className="top-space bottom-space" size={size}>
          {title}
        </Heading>
        <Text className="bottom-space-small" size={size}>
          {subtextOne}
        </Text>
        {mapped}
        <Text className="top-space-small bottom-space" size={size}>
          {subtextTwo}
        </Text>
      </div>
    </section>
  );
};

InformationTwo.propTypes = {
  title: PropTypes.string.isRequired,
  subtextOne: PropTypes.string.isRequired,
  subtextTwo: PropTypes.string.isRequired,
  bullets: PropTypes.array.isRequired,
};
