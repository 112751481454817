import React from "react";
import { Text } from "@chakra-ui/react";
import icon from "../assets/imgs/custom-icon.png";
import { isMobile } from "react-device-detect";

export const List = (props) => {
  const { items } = props;

  const mapped = items.map((item) => {
    return (
      <span key={item}>
        <Text fontWeight={600} fontSize={isMobile ? "md" : "xl"}>
          <img
            src={icon}
            style={{ height: isMobile ? 30 : 48, display: "inline-block" }}
            className="bullet-image"
          />{" "}
          {item}
        </Text>
      </span>
    );
  });

  return mapped;
};
