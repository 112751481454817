import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./InformationThree.scss";
// import { isMobile } from "react-device-detect";
import { ThemeProvider } from "../../index";
import { Button, Heading, Text } from "@chakra-ui/react";

export const InformationThree = (props) => {
  const { title, body, anchor, contrast } = props;
  const global = useContext(ThemeProvider);
  const {
    primaryButton: { buttonText, onClick },
    colorScheme,
    colorContrast,
    highlightColor,
  } = global;

  return (
    <section
      id={anchor}
      className="information-three-outer-wrapper"
      style={{ background: contrast ? highlightColor : colorContrast }}
    >
      <div className="information-three-wrapper">
        <Heading
          color={contrast ? colorContrast : "black"}
          className="top-space bottom-space"
        >
          {title}
        </Heading>
        <Text color={contrast ? colorContrast : "black"}>{body}</Text>
        <Button
          className="top-space bottom-space"
          colorScheme={colorScheme}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </section>
  );
};

InformationThree.propTypes = {
  title: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  contrast: PropTypes.bool.isRequired,
};
