import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
import "./Footer.scss";

export const Footer = (props) => {
  const { text } = props;
  return (
    <section className="footer-wrapper">
      <Text>{text}</Text>
    </section>
  );
};

Footer.propTypes = {
  text: PropTypes.string.isRequired,
};
