import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Form.scss";
// import { isMobile } from "react-device-detect";
import { Formik, Form, Field } from "formik";
import axios from "axios";

import {
  Button,
  Heading,
  Text,
  FormControl,
  Input,
  FormLabel,
  FormHelperText,
  Textarea,
  FormErrorMessage,
  Alert,
} from "@chakra-ui/react";
import formImg from "../../assets/imgs/form-image.png";

const validateEmail = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const FormUI = (props) => {
  const { title, subtitle, body } = props;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return (
    <section id="schedule-your-demo" className="form-wrapper">
      <div className="top-space form-left">
        <Heading size="lg" textAlign="left" color="white">
          <em>{title}</em>
        </Heading>
        <Heading className="bottom-space" color="white">
          {subtitle}
        </Heading>
        <Text className="bottom-space" color="white">
          {body}
        </Text>
        {!success && (
          <Formik
            initialValues={{ name: "", email: "", comments: "" }}
            validate={validateEmail}
            onSubmit={(values, { setSubmitting }) => {
              const message = {
                subject: "Letesoft Form",
                message: values,
              };
              axios
                .post("/email?email=jay@sargentassociates.com", message)
                .then((res) => {
                  console.log(res);
                  setSubmitting(false);
                  setSuccess(true);
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                  setError(true);
                });
            }}
          >
            {({ values, isSubmitting, errors }) => (
              <Form>
                <div className="central-form-wrapper">
                  <div className="left-form">
                    <Field name="name">
                      {({ field }) => (
                        <FormControl color="white" id="name">
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input {...field} type="name" />
                        </FormControl>
                      )}
                    </Field>
                    <br />
                    <Field name="email">
                      {({ field }) => (
                        <FormControl color="white" id="email">
                          <FormLabel htmlFor="email">Email address</FormLabel>
                          <Input {...field} type="email" />
                          <FormHelperText color="white">
                            We&apos;ll never share your email.
                          </FormHelperText>
                          <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </div>
                  <br />
                  <Field name="comments">
                    {({ field }) => (
                      <div className="right-form">
                        <FormControl color="white" id="comments">
                          <FormLabel htmlFor="comments">Comments</FormLabel>
                          <Textarea
                            {...field}
                            placeholder="Please include anything you would like us to know!"
                          />
                        </FormControl>
                      </div>
                    )}
                  </Field>
                </div>
                <Button
                  className="top-space"
                  w="40%"
                  color="white"
                  variant="outline"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={
                    !values.email ||
                    !values.name ||
                    !values.comments ||
                    errors.email
                  }
                >
                  Submit
                </Button>
                <br />
                <br />
                {values.email && errors.email ? (
                  <Alert status="error">
                    Please format your email correctly
                  </Alert>
                ) : null}
              </Form>
            )}
          </Formik>
        )}
        {success && (
          <Alert status="success">
            Thank you for your inquiry! We will be contacting you shortly!
          </Alert>
        )}
        {!success && error && (
          <Alert status="error">
            Something went wrong. Please try again or reach out to
            cameron@letesoft.com
          </Alert>
        )}
      </div>
      <div className="form-right">
        <img
          src={formImg}
          className="form-image"
          alt="screenshot of software"
        />
      </div>
    </section>
  );
};

FormUI.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
