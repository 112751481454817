import React from "react";
import "./App.scss";
import { Footer } from "./CustomSections/index";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTachometerAlt,
  faToolbox,
  faUsers,
  faGlobeAmericas,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

library.add(faTachometerAlt, faToolbox, faUsers, faGlobeAmericas, faChartPie);

import {
  Hero,
  Tabs,
  Icons,
  Bullets,
  BulletsTwo,
  Information,
  InformationTwo,
  InformationThree,
  ThreeStep,
  Testimonials,
  FormUI,
  BulletsThree,
} from "./Sections/index";

import {
  BulletsCopy,
  HeroCopy,
  TabsCopy,
  InformationCopy,
  BulletsTwoCopy,
  FormCopy,
  FooterCopy,
  IconsCopy,
  InformationTwoCopy,
  ThreeStepCopy,
  InformationThreeCopy,
  InformationFourCopy,
  InformationFiveCopy,
  InformationSixCopy,
  InformationSevenCopy,
  BulletsThreeCopy,
} from "./copy";

function App() {
  React.useEffect(() => {
    const handleFn = () => {
      window.scrollTo(window.scrollX, window.scrollY - 100);
    };
    window.addEventListener("hashchange", handleFn);

    return () => {
      window.removeEventListener("hashchange", handleFn);
    };
  }, []);

  return (
    <div className="App">
      <Hero {...HeroCopy} />
      <Tabs {...TabsCopy} />
      <Bullets {...BulletsCopy} />
      <Information {...InformationCopy} />
      <Icons {...IconsCopy} />
      <InformationTwo {...InformationTwoCopy} />
      <BulletsThree {...BulletsThreeCopy} />
      <ThreeStep {...ThreeStepCopy} />
      <BulletsTwo {...BulletsTwoCopy} />
      <InformationThree {...InformationThreeCopy} />
      <InformationThree {...InformationFourCopy} contrast />
      <InformationThree {...InformationFiveCopy} />
      <InformationThree {...InformationSixCopy} contrast />
      <InformationThree {...InformationSevenCopy} />
      <Testimonials />
      <FormUI {...FormCopy} />
      <Footer {...FooterCopy} />
    </div>
  );
}

export default App;
