import React from "react";
import PropTypes from "prop-types";
import "./Tabs.scss";
import { Box } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import star from "../../assets/imgs/custom-icon.png";

export const Tabs = (props) => {
  const { tabs } = props;

  const mapped = tabs.map((item) => {
    return (
      <Box className="box flex-center" key={item} fontSize={isMobile ? 12 : 24}>
        <p style={{ zIndex: 2 }}>{item}</p>
      </Box>
    );
  });

  return (
    <section className="tabs-wrapper bottom-space">
      {mapped}
      <img className="star" src={star} />
    </section>
  );
};
Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};
