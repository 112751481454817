import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./ThreeStep.scss";
// import { isMobile } from "react-device-detect";
import { ThemeProvider } from "../../index";
import { Button, Heading, Text } from "@chakra-ui/react";

const steps = [1, 2, 3];
export const ThreeStep = (props) => {
  const { title, stepOne, stepTwo, stepThree } = props;

  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const mapped = steps.map((item) => {
    let title = "",
      subtext = "";

    switch (item) {
      case 1:
        title = stepOne.title;
        subtext = stepOne.subtext;
        break;
      case 2:
        title = stepTwo.title;
        subtext = stepTwo.subtext;
        break;
      default:
        title = stepThree.title;
        subtext = stepThree.subtext;
        break;
    }

    return (
      <div className="steps-text-wrapper" key={item}>
        <p className="catamaran number">{item}</p>
        <Heading s="md">{title}</Heading>
        <Text className="top-space" s="md">
          {subtext}
        </Text>
      </div>
    );
  });
  return (
    <section className="three-step-wrapper top-space">
      <Heading>{title}</Heading>
      <div className="steps-wrapper">{mapped}</div>
      <Button className="top-space" colorScheme={colorScheme} onClick={onClick}>
        {buttonText}
      </Button>
    </section>
  );
};

ThreeStep.propTypes = {
  title: PropTypes.string.isRequired,
  stepOne: PropTypes.object.isRequired,
  stepTwo: PropTypes.object.isRequired,
  stepThree: PropTypes.object.isRequired,
};
