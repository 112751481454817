import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Hero.scss";
import { Button, Heading, Stack } from "@chakra-ui/react";
import { ThemeProvider } from "../../index";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";

export const Hero = (props) => {
  const global = useContext(ThemeProvider);
  const { title, subtitle } = props;
  const {
    primaryButton: { buttonText: primaryText, onClick },
    colorScheme,
  } = global;

  const titleSize = isMobile ? "lg" : "2xl";
  const subtitleSize = isMobile ? "sm" : "lg";
  const buttonSize = isMobile ? "xs" : "md";

  const video = (
    <ReactPlayer
      width={isMobile ? "100%" : "auto"}
      playing
      controls
      url="https://www.dropbox.com/s/163sxs0p4djluc9/Letesoft%20music%203.mp4?dl=0"
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
      }}
    />
  );
  return (
    <section className="hero-wrapper bottom-space">
      <div className="hero-video-wrapper">
        <div className="flex-column">
          <Heading
            className="bottom-space"
            align="left"
            size={titleSize}
            maxWidth={950}
          >
            {title}
          </Heading>
          <Heading
            className="bottom-space"
            align="left"
            size={subtitleSize}
            maxWidth={950}
            color="grey"
          >
            {subtitle}
          </Heading>
        </div>
        <div style={{ width: 100 }}></div>
        {isMobile ? null : video}
        <div style={{ width: 100 }}></div>
      </div>

      <Stack direction={isMobile ? "column" : "row"}>
        <Button size={buttonSize} colorScheme={colorScheme} onClick={onClick}>
          {primaryText}
        </Button>
        {/* <Button size={buttonSize} colorScheme={colorScheme} variant="outline">
          {secondaryText}
        </Button> */}
      </Stack>

      {isMobile ? video : null}
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
