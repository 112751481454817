import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Information.scss";
import { ThemeProvider } from "../../index";
import { isMobile } from "react-device-detect";
import { Heading, Button } from "@chakra-ui/react";

export const Information = (props) => {
  const { title, subtext } = props;
  const global = useContext(ThemeProvider);
  const {
    colorScheme,
    primaryButton: { buttonText, onClick },
  } = global;

  const titleSize = isMobile ? "lg" : "2xl";
  const subtextSize = isMobile ? "xs" : "md";

  return (
    <section className="information-wrapper">
      <Heading className="bottom-space" size={titleSize} color="white">
        {title}
      </Heading>
      <Heading
        size={subtextSize}
        color="white"
        style={{
          maxWidth: isMobile ? 300 : 900,
          margin: "auto",
          marginBottom: 48,
        }}
      >
        {subtext}
      </Heading>
      <Button
        className="bottom-space"
        colorScheme={colorScheme}
        variant="outline"
        color="white"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </section>
  );
};

Information.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
};
