import React from "react";
import { Tabs } from "../Sections/Tabs/Tabs";
import { Heading } from "@chakra-ui/react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import "./About.scss";
import { Footer } from "../CustomSections";
import { FooterCopy } from "../copy";

export const About = () => {
  return (
    <section className="about-us">
      <Tabs tabs={["About Us"]} />
      <div className="about-us-text">
        <Heading textAlign="center">
          Imagine having one place to manage all the complexities of your
          business.
        </Heading>
        <Icon
          style={{ textAlign: "center", marginTop: 30 }}
          icon="chart-pie"
          size="4x"
        />
        <br />
        <br />
        <br />
        <Heading className="sub-heading" fontSize="lg">
          Through the Letesoft platform, businesses can run reports, build and
          access dashboards with key metrics, book appointments, manage
          pipelines, email and text clients, and much more.
        </Heading>
        <p>
          Letesoft is a CRM, ERP, and business management software all rolled
          into one.
          <br />A few years ago while leading a franchisor operation, our team
          needed a software platform to manage their growing business. We had a
          strong brand and loyal customers, but our internal operations were
          inefficient. We needed tools that had more functionality and the
          ability to scale as we grew. Our reports and data were limited,
          resulting in problems that weren’t even identified until 40-60 days
          after the problem was created. We explored other solutions, but
          nothing was quite right--none had the capabilities for both todays and
          tomorrows needs.
        </p>
        <br />
        <br />
        <Heading className="sub-heading" fontSize="lg">
          So we created our own.
        </Heading>
        <br />
        <p>
          After working with countless business owners and executive leaders, we
          understand the challenges involved in running a successful operation.
          <br />
          <br />
          From lean startups to enterprise organizations, businesses have used
          Letesoft® to provide services in <strong>tens of thousands</strong> of
          transactions <strong>every month</strong>.
          <br />
          <br />
          From reports for leaders down to functionality for front-line
          employees, Letesoft® gives you the <em>ultimate flexibility</em> in
          choosing how you customize and use the platform, providing your team
          with solutions at every level, even as needs change.
        </p>
        <br />
        <Heading className="sub-heading" fontSize="lg">
          Thank you.
        </Heading>
        <br />

        <hr />

        <Footer {...FooterCopy} />
      </div>
    </section>
  );
};
