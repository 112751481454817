export const HeaderCopy = {
  menuItems: [
    { title: "Reporting", link: "/#Reporting" },
    { title: "Client Info", link: "/#Client Info" },
    { title: "Scheduler", link: "/#Scheduler" },
    { title: "Mobile", link: "/#Mobile" },
    { title: "Dispatching", link: "/#Dispatching" },
    { title: "About Us", link: "about-us" },
  ],
};

export const HeroCopy = {
  title: "Field Services Software",
  subtitle:
    "Manage your entire business and increase your profits. Letesoft® empowers your services business to eliminate wasted time and resources.",
};
export const TabsCopy = {
  tabs: [
    "Fully Customizable for Your System",
    "Well-Tested To Streamline Processes",
    "Problem Solving that Empowers Growth",
  ],
};
export const BulletsCopy = {
  title: "Overwhelmed with the way things are?",
  bullets: [
    "Wasted hours on things that could be automated",
    "Poor customer experiences",
    "Inefficient methods and poor turnaround",
    "Disappointing profits despite your hard work",
    "Old habits that squash new opportunities",
    "Uncertainty around key business decisions",
  ],
  subtext:
    "Letesoft® was built to make it all flow smoothly, and put the answers in the palm of your hand.",
};
export const InformationCopy = {
  title: "Designed for Home Service Companies Big and Small",
  subtext:
    "Business owners want their companies to grow, but for those in the home services industry, inefficiencies in their operations too often hold them back from greater profitability. Letesoft® provides a technology platform that makes it easy to efficiently manage it all, providing a catalyst for better business decisions, growth, and profitability.",
};
export const FormCopy = {
  title: "Schedule a Demo Now!",
  subtitle: "Let us help you pinpoint your efficiency leaks!",
  body:
    "Greater efficiency equals greater profit—which means you need to identify and plug those efficiency leaks ASAP.  Our demo walks you through the 7 most common areas where your business could be missing out. \n\n Schedule a free demo to get a jumpstart on uncovering areas where you could be doing better.\n\n",
};
export const BulletsTwoCopy = {
  title: "Letesoft®: The Solution to your operational challenges.",
  bullets: [
    "Developed for small business owners by small business owners",
    "Provides real-time management and reporting",
    "Continuously tested and improved through in-field experience and client feedback",
    "Built specifically to boost home services businesses",
  ],
};
export const BulletsThreeCopy = {
  title: "Industries We Work With:",
  bullets: [
    "Home Services - HVAC, plumbing, landscaping, cleaning, etc.",
    "Construction & Real Estate Management",
    "Medical Practices & Offices",
    "Franchised Organizations",
  ],
};
export const IconsCopy = {
  title: "Invest in a System that Boosts Your Potential",
  iconText: [
    {
      title: "Organize for Long-Term Success",
      subtext:
        "Improve your processes with the last system upgrade you'll ever need.",
      faIcon: "toolbox",
    },
    {
      title: "Control Your Business",
      subtext: "Tailer the system to suit your needs and maximize your growth.",
      faIcon: "tachometer-alt",
    },
    {
      title: "Create a path to Higher Profits",
      subtext:
        "Level-up software that empowers your people and enables excellence.",
      faIcon: "users",
    },
  ],
};
export const InformationTwoCopy = {
  title: "Stop Leaving Money on the Table",
  subtextOne:
    "How much would you pay to 5x your business? Letesoft® helps you get there by improving efficiencies across every area of your company, whether it’s: ",
  bullets: [
    "Better Routes For Your Technicians",
    "Better Insight Into How Well Your Advertising is Working",
    "Increasing Profitability",
  ],
  subtextTwo:
    "This means we're saving you money, getting you money faster, and showing you where to spend your money for better results.",
};
export const ThreeStepCopy = {
  title: "Get Letesoft: Your Business Software Solution",
  stepOne: {
    title: "Talk through your needs",
    subtext:
      "Schedule a demo and we can discuss the solutions and pricing that fit you best.",
  },
  stepTwo: {
    title: "We’ll get you up and running",
    subtext:
      "Get started with a hands-on onboarding team who simplifies the transition process.",
  },
  stepThree: {
    title: "Start seeing a difference",
    subtext:
      "See how efficient organization and clear data improves your business within weeks.",
  },
};
export const InformationThreeCopy = {
  title: "Real-Time Reporting",
  anchor: "Reporting",
  body:
    "Rather than waiting until the month is finished, with Letesoft, you can run the business reports that you want in real-time today. Report generating leads to better adjustments and course corrections, and overall improved operational efficiency.",
};
export const InformationFourCopy = {
  title: "Comprehensive Client Info",
  anchor: "Client Info",
  body:
    "Letesoft offers customer data retention systems so you can better understand the client and their needs. Letesoft stores full information on client history, past services purchased, client contact info, and any other information your technicians might need.",
};
export const InformationFiveCopy = {
  title: "Zone Scheduler",
  anchor: "Scheduler",
  body:
    "Letesoft provides the ability to maximize efficiency by only scheduling bookable jobs in set zones so technicians aren't driving back and forth between large geographical locations. Making adjustments to open/closed zones is as easy as making a few clicks.",
};
export const InformationSixCopy = {
  title: "Mobile Reporting",
  anchor: "Mobile",
  body:
    "Letesoft allows you to access daily, weekly, and even year-to-date reports, all updated in real-time, on your mobile phone. Always be connected to the different metrics, cycles, and overall health of your business.",
};
export const InformationSevenCopy = {
  title: "Dispatching",
  anchor: "Dispatching",
  body:
    "Whether it is through area mapping/clustering, the robust online scheduler, or the integrated phone system Letesoft offers, the dispatch system improves revenue and increases operational efficiency.",
};

export const FooterCopy = {
  text: `Copyright ${new Date().getFullYear()} Letesoft®`,
};
