import React, { createContext } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import routes from "./routes";

export const ThemeProvider = createContext();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <ThemeProvider.Provider
          value={{
            highlightColor: "#356951",
            colorScheme: "green",
            colorContrast: "white",
            primaryButton: {
              buttonText: "Schedule Your Demo Today!",
              onClick: (e) => {
                e.preventDefault();
                window.location.href = "#schedule-your-demo";
              },
            },
            secondaryButton: {
              buttonText: "Download our Free Guide!",
            },
          }}
        >
          {routes}
        </ThemeProvider.Provider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
