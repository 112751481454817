import React from "react";
import { Route, Switch } from "react-router-dom";
import App from "./App";
import { About } from "./Pages/About";
import Header from "./CustomSections/Header/Header";
import { HeaderCopy } from "./copy";

export default (
  <>
    <Header {...HeaderCopy} />
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/about-us" component={About} />
    </Switch>
  </>
);
